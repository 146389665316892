<template>
  <div class="list-manage order-details">
    <section class="list-manage-table">
      <div class="table-title">
        <span class="title">订单修改详情</span>
        <span class="order-number">订单号：{{list[0].order_id}}</span>
      </div>
      <div class="status-bar">
        <div class="status">
          <img src="~@/assets/images/icon_time.png" class="icon" alt="" />{{
            list[0].order_state == 1
              ? "已取消"
              : list[0].order_state == 2
              ? "待确认"
              : list[0].order_state == 3
              ? "预约成功"
              : list[0].order_state == 4
              ? "预约失败"
              : list[0].order_state == 5
              ? "结算申请中"
              : list[0].order_state == 6
              ? "结算成功"
              : list[0].order_state == 7
              ? "结算失败"
              : list[0].order_state == 8
              ? "完结申请中"
              : list[0].order_state == 9
              ? "完结失败"
              : "已完结"
          }}
        </div>
        <div class="btn-group">
          <!-- <span class="btn">联系客服</span> -->
          <!-- <span class="btn btn-orange" @click="cancellation" v-show="flagcacle"
            >取消订单</span
          > -->
        </div>
      </div>
      <div class="order-info">
        <div class="order-info-title">承租方</div>
        <div class="order-info-main">
          <div class="info-item">
            <span class="label">联系电话：</span>{{ userlist.phone }}
          </div>
          <div class="info-item">
            <span class="label">原名称：</span>{{ list[0].name }}
          </div>
          <div class="info-item">
            <span class="label">修改后名称：</span>{{ list[0].name }}
          </div>
          <div class="info-item">
            <span class="label">原设备使用地址：</span>{{ list[0].address
            }}<i class="el-icon-location-outline"></i>
          </div>
          <div class="info-item">
            <span class="label">修改后设备使用地址：</span>{{ list[0].address
            }}<i class="el-icon-location-outline"></i>
          </div>
        </div>
      </div>

      <div class="thead">
        <span class="th">商品信息</span>
        <span class="th">租赁方式</span>
        <span class="th">数量</span>
        <span class="th">时长</span>
      </div>
      <div
        class="tobody"
        v-for="(item, index) in resdata"
        :key="index"
        v-show="flagchirden"
      >
        <span>子订单号：</span>{{ item.order_id  }}
        <!-- <span class="btn btn-orange a" @click="btnregire(item.order_id)" >修改订单</span > -->
        <section class="order-device-list">
          <div class="device-list-item">
            <div class="device-list-header">
              <img v-if="item.consumer_head == null || item.consumer_head == '' " src="~@/assets/images/temp/temp_04.png"  class="avatar" alt="" />
              <img v-else :src="item.consumer_head" class="avatar" alt="" />{{
                item.company_name
              }}
            </div>
            <div class="device-info-list">
              <div
                class="device-info-item"
                v-for="(item2, index) in item.children"
                :key="index"
              >
                <img :src="item2.show_img" class="pic" alt="" />
                <div class="tdhead">
                  <div class="name">{{ item2.name }}</div>
                  <div><div class="tag">挖掘机</div></div>
                </div>
                <div class="td">
                  <span class="f-orange"
                    >¥{{ item2.rental_fee }}/{{
                      item2.rental_type == 0
                        ? "日"
                        : item2.rental_type == 1
                        ? "月"
                        : item2.rental_type == 2
                        ? "半年"
                        : "年"
                    }}</span
                  >
                </div>
                <div class="td">
                  <div>租赁台数:{{ item2.num }}台</div>
                </div>
                <div class="td">
                  租赁时长{{ item2.rental_time
                  }}{{
                    item2.rental_type == 0
                      ? "日"
                      : item2.rental_type == 1
                      ? "月"
                      : item2.rental_type == 2
                      ? "半年"
                      : "年"
                  }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="tobody" v-show="flagborther">
        <span>子订单号：</span>{{ order_ids
        }}<span class="btn btn-orange a" @click="btnregiremodel(order_ids)"
          >修改订单</span
        >
        <section class="order-device-list">
          <div class="device-list-item">
            <div class="device-list-header">
              <img
                :src="getchildrenlist.consumer_head"
                class="avatar"
                alt=""
              />{{ getchildrenlist.company_name }}
            </div>
            <div class="device-info-list">
              <div
                class="device-info-item"
                v-for="(item2, index) in getchildrenlist.order_info"
                :key="index"
              >
                <img :src="item2.show_img" class="pic" alt="" />
                <div class="tdhead">
                  <div class="name">{{ item2.name }}</div>
                  <div>
                    <div class="tag">{{ item2.type_name }}</div>
                  </div>
                </div>
                <div class="td">
                  <span class="f-orange"
                    >¥{{ item2.rental_fee }}/{{
                      item2.rental_type == 0
                        ? "日"
                        : item2.rental_type == 1
                        ? "月"
                        : item2.rental_type == 2
                        ? "半年"
                        : "年"
                    }}</span
                  >
                </div>
                <div class="td">
                  <div>租赁台数:{{ item2.num }}台</div>
                </div>
                <div class="td">
                  租赁时长{{ item2.rental_time
                  }}{{
                    item2.rental_type == 0
                      ? "日"
                      : item2.rental_type == 1
                      ? "月"
                      : item2.rental_type == 2
                      ? "半年"
                      : "年"
                  }}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="order-info">
        <div class="order-info-title">订单信息</div>
        <div class="order-info-main">
          <div class="info-item">
            <span class="label">订单时间：</span>{{ list[0].create_time }}
          </div>
          <div class="info-item">
            <span class="label">申请租赁时间：</span
            >{{ list[0].rental_time_start }}
          </div>
          <div class="info-item">
            <span class="label">实际租赁时间：</span
            >{{ list[0].reality_rental_time_start }}
          </div>
          <div class="info-item">
            <span class="label">实际归还时间：</span
            >{{ list[0].reality_rental_time_end }}
          </div>
          <div class="info-item">
            <span class="label">备注：</span>{{ list[0].remark }}
          </div>
        </div>
      </div>
      <div class="estimate-cost">
        预估费用：￥{{ list[0].estimate_rental_fee }}<br />实际使用费用：<small
          >¥</small
        ><big>{{ list[0].reality_rental_fee }}</big>
      </div>
    </section>
  </div>
</template>

<script>
import {
  getorderdetail,
  getdetail,
  getuserorder,
  getchildren,
  getorderdelete,
} from "@/api/over.js";
import kits from "@/kits/index";
export default {
  data() {
    return {
      list: "",
      list1: "",
      resdata: [],
      price: null,
      order_info_id: "",
      order_id: "",
      order_ids: "",
      getchildrenlist: [],
      parent_order_id: "",
      flagchirden: false,
      flagborther: false,
      flagcacle: false,
      userlist:[],
      pagedata: {
        inputs: "1",
        pagination: {
          pageNumber: 1,
          pageSize: 20,
        },
        searchs: "2",
      },
      noModifyStatus: [  10],
    };
  },
  mounted() {
    // console.log(' this.order_ids', this.order_ids)

    // console.log('this.order_info_id',this.order_info_id)
    if (this.$route.query.id) {
      this.flagchirden = true;
      this.order_info_id = this.$route.query.id;
      this.order_id = this.$route.query.id;
      getdetail({ inputs: { order_id: this.order_id } }).then((res) => {
        this.list = res.data;

        this.list[0].rental_time_start =
          this.list[0].rental_time_start.substring(0, 10);

        if (this.list[0].order_state == 2 || this.list[0].order_state == 4  ) {
              if( this.list[0].is_procet==1){
              this.flagcacle = true;
              }

            }

        else{
          this.flagcacle = false;
        }
        console.log("这是请求的数据111=", this.list);
      });
      getorderdetail({
        inputs: { order_info_id: this.order_info_id },
        pagination: { pageNumber: 1, pageSize: 20 },
        searchs: {},
      }).then((res) => {
        this.list1 = res.data.list;
        if (this.list1[0].rental_type == 0) {
          this.list1[0].rental_type = "日";
        } else if (this.list1[0].rental_type == 1) {
          this.list1[0].rental_type = "月";
        } else if (this.list1[0].rental_type == 2) {
          this.list1[0].rental_type = "半年";
        } else if (this.list1[0].rental_type == 3) {
          this.list1[0].rental_type = "年";
        }
        console.log("=======", this.list1[0]);
      });
      getuserorder({ inputs: { order_id: this.order_id } }).then((res) => {
      this.userlist=res.data
        for (let i = 0; i < res.data.order.length; i++) {
          res.data.order[i].children = res.data.order_info[i];
          this.price += res.data.order[i].estimate_rental_fee;
        }
        this.resdata = res.data.order;
        this.resdata.forEach(e => {
            e.consumer_head=kits.img.url(
                e.consumer_head
            );
            console.log("=========e",e)
            e.children.forEach(ee => {
                ee.show_img = kits.img.url(
                        ee.show_img
                );
            });
        });
        // this.resdata[0].consumer_head = kits.img.url(
        //   this.resdata[0].consumer_head
        // );
      });
    } else {
      this.flagborther = true;
      this.flagcacle = false;
      this.order_ids = this.$route.query.order_id;

      getchildren({ inputs: { order_id: this.order_ids } }).then((res) => {
        this.getchildrenlist = res.data;
        this.parent_order_id = this.getchildrenlist.parent_order_id;
        this.order_id = this.getchildrenlist.parent_order_id;
        this.getchildrenlist.consumer_head = kits.img.url(
          this.getchildrenlist.consumer_head
        );
        this.getchildrenlist.order_info.forEach(e => {
          e.show_img=kits.img.url(
            e.show_img
        );
        });
        getdetail({ inputs: { order_id: this.parent_order_id } }).then(
          (res) => {
            console.log("bbbbbb", this.parent_order_id);
            this.list = res.data;
            this.list[0].rental_time_start =
              this.list[0].rental_time_start.substring(0, 10);
            if (
              this.list[0].order_state == 2 ||
              this.list[0].order_state == 4

            ) {
              if( this.list[0].is_procet==1){
              this.flagcacle = true;
              }

            }
            else{
              this.flagcacle = false;
            }
            console.log("这是请求的数据2222", this.list);
          }
        );
        getorderdetail({
        inputs: { order_info_id:this.parent_order_id },
        pagination: { pageNumber: 1, pageSize: 20 },
        searchs: {},
      }).then((res) => {
        this.list1 = res.data.list;
        if (this.list1[0].rental_type == 0) {
          this.list1[0].rental_type = "日";
        } else if (this.list1[0].rental_type == 1) {
          this.list1[0].rental_type = "月";
        } else if (this.list1[0].rental_type == 2) {
          this.list1[0].rental_type = "半年";
        } else if (this.list1[0].rental_type == 3) {
          this.list1[0].rental_type = "年";
        }
        console.log("=======", this.list1[0]);
      });
      getuserorder({ inputs: { order_id: this.parent_order_id } }).then((res) => {
      this.userlist=res.data
        for (let i = 0; i < res.data.order.length; i++) {
          res.data.order[i].children = res.data.order_info[i];
          this.price += res.data.order[i].estimate_rental_fee;
        }
        this.resdata = res.data.order;
        this.resdata.forEach(e=> {
          e.consumer_head=kits.img.url(
            e.consumer_head
        );
        e.children.forEach(item => {
          item.show_img=kits.img.url(
            e.consumer_head
          );
        });

        });
        // this.resdata[0].consumer_head = kits.img.url(
        //   this.resdata[0].consumer_head
        // );
      });
      });
    }
  },
  methods: {
    cancellation() {
      if (this.$route.query.id) {
        getorderdelete({ inputs: { order_id: this.order_id } }).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "取消成功",
              type: "success",
            });
            this.$router.back();
          } else {
            this.$message.error("取消失败");
          }
        });
      } else {
        getorderdelete({ inputs: { order_id: this.order_id } }).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: "取消成功",
              type: "success",
            });
          } else {
            this.$message.error("取消失败");
          }
        });
      }
    },
    noModifyTips(order_state) {
      switch (order_state) {
        case 2:
          this.$message({
            message: "订单申请待确认中，暂时无法修改",
            type: "warning",
          });
          break;
        case 4:
          this.$message({
            message: "订单申请失败，无法修改",
            type: "warning",
          });
          break;
        case 5:
          this.$message({
            message: "订单结算申请中，暂时无法修改",
            type: "warning",
          });
          break;
        case 8:
          this.$message({
            message: "订单完结申请中，暂时无法修改",
            type: "warning",
          });
          break;
        case 10:
          this.$message({
            message: "订单已完结，无法修改",
            type: "warning",
          });
          break;

        default:
          break;
      }
    },
    //订单管理
    btnregire(ids) {
      console.log("btnregire");
      if (this.noModifyStatus.indexOf(this.list[0].order_state) !== -1) {
        this.noModifyTips(this.list[0].order_state);
      } else {
        this.$router.push({
          path: "/personalCenter/orderModify",
          query: {
            bigid: this.order_info_id,
            id: ids,
            modified_by: 2,
          },
        });
      }
    },

    //出租订单
    btnregiremodel(ids) {
      console.log("btnregiremodel");
      if (this.noModifyStatus.indexOf(this.list[0].order_state) !== -1) {
        this.noModifyTips(this.list[0].order_state);
      } else {
        this.$router.push({
          path: "/personalCenter/orderModify",
          query: {
            bigid: this.order_ids,
            id: ids,
            modified_by: 1,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.tobody {
  margin-left: 10%;
  margin-top: 2%;
}
.tobody .a {
  margin-left: 70%;
}
.td {
  margin-left: 15%;
  font-size: 1em;
}
.order-details .list-manage-table .thead .th:nth-of-type(3),
.order-details .list-manage-table .tbody .td:nth-of-type(3) {
  width: 172px;
}
.list-manage .list-manage-table .thead .th:nth-of-type(1),
.list-manage .list-manage-table .tbody .td:nth-of-type(1) {
  width: 320px;
  padding-left: 20px;
}
.order-details .order-device-list .device-info-list {
  padding: 10px 25px 0;
}
.order-details .order-device-list .device-info-list .device-info-item {
  padding: 0px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
</style>
